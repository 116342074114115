<template>
	
	<div class="pc_root">
		
		<!-- 樱花特效-->
			<div class="cherry">
				<img id="yinghua" src="../../assets/pc/yinghua.png" alt="">
			</div>
		
		<div v-if="isNewPage">
			<!-- <div id="pc_none" /> -->
			<div id="pc_newpage-root">
				<newpage :newid="newInfo.id" :title="newInfo.title" :date="newInfo.date"></newpage>
			</div>
		
			<div class="newpage_footer-root">
				<div class="footer-info"></div>
				<p class="yszc" v-on:click="yszcClick()">隐私政策</p>
			</div>
		</div>


		<full-page class="main-content" :options="options" ref="page" v-if="!isNewPage">

			<div class="section section-top">
				<div class="top-page">
					
					<!-- 背景视频-->
					<!-- style="width: 100vw; height: 100vh;" -->
					<video preload muted = "muted" autoplay="autoplay" loop="loop" 
										class="bgv"  
										id="myVideo">
											<source src="../../assets/pc/bgv.mp4">
										</video>
										

					<div class="pc_logo" />
					<!-- <div class="pc_play" v-on:click="playVideo('https://www.youtube.com/embed/MtMO-gBF3pQ')" /> -->
					<!--已完成预约，关闭该功能 div class="pc_appointment" v-on:click="getPreRegistration(1)" /-->
					<div class="pc_appointment"/>
					<div class="pc_download">
						<!-- app -->
						<a  class="item app fpoint" title="App Store"
							rel="nofollow" target="_blank" fpoint="center_menu_cms@module@AppStore"
							v-on:click="OnAppleClick()" >
							<div class="app" />
						</a>
						<!-- google -->
						<a class="item google fpoint" title="Google Play" rel="nofollow" target="_blank"
							fpoint="center_menu_cms@module@Googleplay"
							v-on:click="OnGoogleClick()">
							<div class="google"/>
						</a>
					</div>
					<div class="pc_download pc_line2">
						<!-- leidian -->
						<a class="item leidian fpoint" title="ldplayer" rel="nofollow" target="_blank"
							fpoint="center_menu_cms@module@ldplayer"
							v-on:click="OnLeidianClick()">
							<div class="leidian"/>
						</a>
						<!-- bluestacks -->
						<a  class="item bluestacks fpoint" title="bluestacks"
							rel="nofollow" target="_blank" fpoint="center_menu_cms@module@bluestacks"
							v-on:click="OnBlueStacksClick()" >
							<div class="bluestacks" />
						</a>
					</div>
					
					<div class="pc_download pc_line3">
						<!-- kunyue -->
						<a  class="item kunyue fpoint" title="官方下載"
							rel="nofollow" target="_blank" fpoint="center_menu_cms@module@kunyue"
							v-on:click="OnKunyueClick()" >
							<div class="kunyue" />
						</a>
					</div>

					<!--div class="pc_facebook_fans">
						<div class="pc-fb-container">
							
							<iframe 
							src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fakaxiajihua&tabs=timeline&width=599&height=318&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=332539864661013" 
							style="position: static;
							visibility: visible; 
							width: 500px; 
							padding: 0px; 
							border: none;  
							margin-top: 0px; 
							height: 3.6rem;" 
							scrolling="no" 
							frameborder="0" 
							allowfullscreen="true" 
							allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
							</iframe>
							
						</div>
					</div-->	

					<div class="top-down" />


					<!-- <div class="slide">
			            section1
			          </div> -->

				</div>
			</div>

			<!--删除预约奖励相关 div class="section section-reward">

				<div class="pc-reward-page">

					<div class="pc_t-reward pc_title" />

					<div class="pc-reward-content">

						<div class="reward-item" v-for="item in rewardThresholdList">
							<img class="reward-item-img" :src="require(`../../assets/pc/${item.img_name}`)" alt="" />
							<img class="reward-completed-item" :src="require(`../../assets/pc/reward_completed.png`)"
								alt="" v-if="item.num <= preRegistrationNum ? true : false">
							</img>

						</div>

					</div>
				</div-->

				<!-- <div class="box2">
			          section2
			        </div> -->

			<!--/div-->



			<div class="section section-news">

				<div class="pc-news-page">

					<div class="pc_t-news pc_title" />
					
					<div class="pc-news-content-portrait">
						
						<div class="pc-news-left">
							
							<div class="pc-news-left-top">
								<div class="swiper-container" id="pcnewsTop">
									<div class="swiper-wrapper">
										<!-- :style="{background: 'url('+ item +') center ',
											        'background-repeat': 'no-repeat'}" -->
										<div class="swiper-slide " v-for="item in newsTopList">
											<img class="pc-news-top-stage" :src="item.img">
										</div>
									</div>
								</div>
							</div>
							
							
							<div class="pc-news-left-down">
								<div class="pc-news-container">
									
									<ul class="pc_news_list">
										<li class="news_item" v-for="(item,index) in newsList"
											v-if="(index>=newsPage*newsViewNum)&&(index<newsPage*newsViewNum+newsViewNum)"
											v-on:click="newsClick(item)">
											<p class="n_date">{{item.date}}</p>
											<p class="n_title">{{ellipsis(item.title)}}</p>
										</li>
									</ul>
									<div class="pc_news_page">
										<div :class="item!=newsPage?'item':'item active_item'" v-for="item in newsPages"
											v-on:click="newsPageClick(item)">
											{{item+1}}
										</div>
									</div>
									
								</div>
							
							</div>
							
						</div>
						
						<div class="pc-news-right">
							 
							 <iframe 
							 src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fakaxiajihua&tabs=timeline&width=500&height=1024&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=332539864661013" 
							 style="position: static;
							 visibility: visible; 
							 width: 500px; 
							 padding: 0px; 
							 border: none;  
							 margin-top: 1rem; 
							 height: 7.71rem;" 
							 scrolling="no" 
							 frameborder="0" 
							 allowfullscreen="true" 
							 allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
							 </iframe>
							
						</div>	
						
					</div>
					

					<!-- <div class="pc-news-content">

						<div class="pc-news-top">
							<div class="swiper-container" id="pcnewsTop">
								<div class="swiper-wrapper">
									<div class="swiper-slide " v-for="item in newsTopList">
										<img class="pc-news-top-stage" :src="item.img">
									</div>
								</div>
							</div>
						</div>
						
						<div class="pc-news-down">
							<div class="item news-container">
								<div class="news-container">
									
									<ul class="pc_news_list">
										<li class="news_item" v-for="(item,index) in newsList"
											v-if="(index>=newsPage*newsViewNum)&&(index<newsPage*newsViewNum+newsViewNum)"
											v-on:click="newsClick(item)">
											<p class="n_date">{{item.date}}</p>
											<p class="n_title">{{ellipsis(item.title)}}</p>
										</li>
									</ul>
									<div class="pc_news_page">
										<div :class="item!=newsPage?'item':'item active_item'" v-for="item in newsPages"
											v-on:click="newsPageClick(item)">
											{{item+1}}
										</div>
									</div>
									
								</div>
							
							</div>
							
							<div class="item pc-news-facebook-container">
								
								<iframe 
								src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fakaxiajihua&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=332539864661013"
								 style="position: static;
								 visibility: visible; 
								 width: 500px; 
								 padding: 0px; 
								 border: none;  
								 margin-top: 0px; 
								 height: 6rem;" 
								 scrolling="no" 
								 frameborder="0" 
								 allowfullscreen="true" 
								 allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
								 </iframe>
								 
							</div>
						</div>

					</div> -->

				</div>

			</div>

			
			
			<div class="section section-feature">
			
				<div class="pc-feature-page">
			
					<div class="pc_t-feature pc_title" />
			
					<div class="pc-feature-content">
						
						<div class="pc-feature-container-line">
							
							<div class="swiper-container" id="features">
								<div class="swiper-wrapper">
									<div class="swiper-slide" v-for="item in featureList">
										<img class="stage" :src="item.img">
									</div>
										
								</div>
							</div>
							
						</div>
						
						<!-- 左右按钮 -->
						<div class="feture-btns">
							<a href="javascript:;" class="btn fbtn-left fpoint" id="featurePrevBtn"
								fpoint="page_cms_ads@previous@feature"></a>
							<a href="javascript:;" class="btn fbtn-right fpoint" id="featureNextBtn"
								fpoint="page_cms_ads@next@feature"></a>
						</div>
						
						<!-- 头像列表 -->
						<div class="swiper-pagination select-box" id="selectBox"></div>
			
					</div>
				</div>
			
			</div>
			
			
			
			<div class="section section-character">
			
				<div class="pc-character-page">
			
					<div class="pc_t-character pc_title" />
			
					<div class="pc-character-content">
						
						<!-- 角色列表 -->
						<div class="swiper-container roles" id="roleList">
							<div class="swiper-wrapper">
								<div class="swiper-slide" v-for="item in roleList" :key="item.name">
									<img class="stage" :src="item.img" :alt="item.name">
									<div class="info">
										
										<!-- <p class="name">{{item.name}}</p>
										<p class="author"> CV: {{item.cv}}</p> -->
										
										<img id="role-info" :src="item.rinfo" alt="" />
										
										<!--p class="description">{{item.des}}</p-->
									</div>
								</div>
							</div>
						</div>
						
						<div id = "role-stage-bg"></div>
						
						<!-- 头像列表 -->
						<div class="swiper-pagination avatars" id="avatarBox"></div>
						<!-- 人物介绍背景 -->
						<!--div class="info_bg"></div-->
						<!-- 左右按钮 -->
						<div class="btns container">
							<a href="javascript:;" class="btn pc-btn-left fpoint" id="rolePrevBtn"
								fpoint="page_cms_ads@previous@character"></a>
							<a href="javascript:;" class="btn pc-btn-right fpoint" id="roleNextBtn"
								fpoint="page_cms_ads@next@character"></a>
						</div>
			
					</div>
				</div>
			
			</div>
			
			<div class="section section-story">
			
				<div class="pc-story-page">
			
					<div class="pc_t-story pc_title" />
			
					<div class="pc-story-content">
						
						<div class="pc-story-container-top">
							<!-- :style="{background: 'url('+ item.img +') center ',
								        'background-repeat': 'no-repeat'}" -->
							<div class="story-content-item-top" v-for="item in storyBGList" >
								<img class="story-content-img-top" :src="item.img" alt=""/>
							</div>
						</div>
						
						
						<div class="pc-story-container-bottom">
							<div class="story-content-item" v-for="item in storyBGListDown" >
								<img class="story-content-img" :src="item.img" alt=""/>
							</div>
						</div>
			
					</div>
					
					<div class="pc-footer">
						<p class="yszc" v-on:click="yszcClick()">隐私政策</p>
					</div>
					
				</div>
			
			</div>
			
			
			

			<!-- <div class="section">
				<div class="box3">
					section3
				</div>
			</div> -->

		</full-page>
		
		
		<!--视频播放-->
		<div class="mask video" id="pc_videoModal" v-show="isShowVideo">
			<button class="pc_vclose" v-on:click="closeVideo"></button>
			<iframe class="iframe" :src="videoUrl" frameborder="0" id="pc_videoFrame"></iframe>
		</div>


		<div class="pc-right-menu" >
			<div class="pc-close-box hide"></div>

			<div class="pc-menu-box">

				<!-- :src="require(`../../assets/pc/${item.name_cn}`)"
				alt="" -->
				<div class="pc-menu-list">
					<div :class="selectIndex!=item.id?'item':'item item_active'" v-for="(item,index) in menuList"
						v-on:click="menuClick(item.id)" :style="{background: 'url('+ item.menuSrc +') center ',
						        'background-repeat': 'no-repeat'}">
					</div>

				</div>

				<div class="pc-close"></div>
			</div>


		</div>


	</div>

</template>

<script>
	import newpage from './newpage.vue'
	import axios from 'axios'
	
	      

	export default {
		name: '',
		components: {
			newpage
		},
		methods: {
			OnThunderClick: function(){
				console.log("OnThunderClick==>");
				window.open("https://www.ldplayer.tw/games/com-kunyue-ecyakxjh-on-pc.html");
			},
			OnAppleClick: function(){
				console.log("OnAppleClick==>");
				gtag_report_conversion_ios("https://app.adjust.com/q2ipuhk?redirect=https%3A%2F%2Fapps.apple.com%2Ftw%2Fapp%2Fid1594167524");
			},
			OnGoogleClick: function(){
				console.log("OnGoogleClick==>");
				gtag_report_conversion("https://app.adjust.com/g1l48ec?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.kunyue.ecyakxjh");
			},
			OnLeidianClick: function(){
				console.log("OnLeidianClick==>");
				gtag_report_conversion("https://www.ldplayer.tw/games/com-kunyue-ecyakxjh-on-pc.html");
			},
			OnBlueStacksClick: function(){
				console.log("OnLeidianClick==>");
				gtag_report_conversion("https://wp-s.bluestacks.com/tw/apps/role-playing/a-ka-xia-jihua-on-pc.html");
			},
			OnKunyueClick: function(){
				console.log("OnKunyueClick==>");
				gtag_report_conversion("https://app.adjust.com/e4lh5mc?redirect=https%3A%2F%2Fecyrhk.mihuahudong.com%2Fapps%2Fakxjh_mycard.apk");
			},
			initFaceBook: function() {
				console.log("初始化Facebook");
				window.fbAsyncInit = function() {
				        FB.init({
							appId: '2040112362827659',
				          	autoLogAppEvents : true,
				          	xfbml: true,
				          	version: 'v12.0'
				        });
				
						FB.login();
				      };
			},
			getFacebookPage: function() {
				console.log("获取facebook_Page");
				
				return;
				
				FB.getLoginStatus(
				
				function(response) {
					console.log("FB.getLoginStatus==>" + JSON.stringify(response));
				    statusChangeCallback(response);
				}
				);
				
				
				window.FB.api(
				    "/100070074465684/assigned_pages",
				    function (response) {
						
						console.log(response);
						
				      if (response && !response.error) {
						  console.log("获取facebook_Page success");
				        /* handle the result */
				      }
				    }
				);
			},
			initVideo: function() {
				console.log("初始化视频");
				myVideo.play();
			        //初始化视频方法
			        },
			//樱花飘落特效
			Cherry_Blossoms: function() {
				//设置默认值
				var defaults = {
					ele: '.cherry', //切换对象
					is_Cherry: true, //是否生成樱花
					image_min: 10, //花瓣最小宽度和高度
					image_max: 50, //花瓣最大宽度和高度
					time_min: 10000, //花瓣最快下坠时间
					time_max: 20000, //花瓣最慢下坠时间
					interval: 500 //花瓣生成时间间隔
				};
				console.log("设置默认值f")
				var params = {
					ele: '.cherry', //切换对象
					is_Cherry: true, //是否生成樱花
					image_min: 10, //花瓣最小宽度和高度
					image_max: 50, //花瓣最大宽度和高度
					time_min: 10000, //花瓣最快下坠时间
					time_max: 20000, //花瓣最慢下坠时间
					interval: 500 //花瓣生成时间间隔
				};
				//定义默认参数，其中若在html页面设置了param是，这里的params会替换defaults
				var par = $.extend({}, defaults, params);
				/**
				 * [m,n]随机数函数
				 */
				function randomNum(minNum, maxNum) {
					switch (arguments.length) {
						case 1:
							return parseInt(Math.random() * minNum + 1, 10);
							break;
						case 2:
							return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
							break;
						default:
							return 0;
							break;
					}
				}
			
				/**
				 * 生成樱花花瓣
				 */
				if (par.is_Cherry) {
			
					console.log("生成樱花花瓣")
			
					setInterval(function() {
						var time; //下落的持续时间，取随机数
						var image_daxiao; //花瓣大小取随机数
						var margin_left; //花瓣所在位置，取随机数
						margin_left = randomNum(5, 100);
						time = randomNum(par.time_min, par.time_max);
						//console.log(time);
						image_daxiao = randomNum(par.image_min, par.image_max);
						//console.log(image_daxiao);
						//console.log($('#yinghua:last-child'));
						//注意此处的加载路径问题
						$('#yinghua:last-child').after(
							'<img id="yinghua" src="/img/yinghua.2f500675.png" alt="">');
						$('#yinghua:last-child').css({
							'margin-left': margin_left + '%',
							'width': image_daxiao + 'px',
							'height': image_daxiao + 'px'
						});
						$('#yinghua:last-child').animate({
							left: -15 + '%',
							top: 100 + '%',
							deg: -540
						}, {
							step: function(deg) {
								//console.log(deg);
								$(this).css({
									"transform": "rotate3d(1,.2,0," + deg + "deg)"
								});
							},
							duration: time
						}, time);
						setTimeout(function() {
							$('#yinghua:first-child').remove();
						}, time - 5000);
					}, par.interval);
				}
			},
			playVideo: function(url) {
				if (this.videoUrl != url) {
					this.videoUrl = url;
				}
				this.isShowVideo = true;
			},
			closeVideo: function() {
				this.isShowVideo = false;
				this.videoUrl = "";
			},
			yszcClick: function() {
				window.open('https://www.mofang.com.tw/privacy');
				//window.open('http://reshk3.mihuahudong.com/mrzj/website/fz-mrzj-privacy-policy.html');
			},
			afterLoad: function(origin, destination, direction) {
				//console.log('afterLoad...'+direction);
				if (this.isMoving) {
					this.isMoving = false;
					return;
				} else {
					if (direction == "up" && this.selectIndex > 1) {
						this.selectIndex--;
					} else if (direction == "down" && this.selectIndex < 6) {
						this.selectIndex++;
					}
				}
				
				this.initVideo();

			},
			handleScroll: function() {
				// 页面滚动距顶部距离
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
					document.body.scrollTop
				var scroll = scrollTop - this.i;
				this.i = scrollTop;
				if (scroll < 0) {
					console.log('up')
				} else {
					console.log('down')
				}
			},
			handleResize: function() {
				// 1900 -> 99px
				// 1881 -> 98px
				// 1862 -> 97
				// 1266 -> 66
				const htmlEle = document.querySelector('html');
				const wd = window.innerWidth - 17;
				console.log(wd, 'wd')
				if (wd <= 1900) {
					if (wd == 1900) {
						htmlEle.style.fontSize = '99px'; //99px
					} else {
						if (wd <= 1266) {
							htmlEle.style.fontSize = '66px'; //66px
							return;
						};
						const dn = parseInt((1900 - wd) / 19);
						console.log(dn, 'dn')
						htmlEle.style.fontSize = `${99 - dn}px`; //99
					}
					return;
				}
				htmlEle.style.fontSize = '100px'; //100px

			},
			menuClick: function(id) {
				
				this.isMoving = true;
				this.isNewPage = false;
				console.log("menuClick==>" + id);
				this.selectIndex = id;
				//this.$refs.page.api.moveSectionDown();
				this.$refs.page.api.moveTo(id);
				myVideo.play();
			},
			getPreRegistration: function(times) {
				console.log("getPreRegistration==>" + times);
				// if(times == 0){
				// 	this.isShowAppointmentUI = false;
				// }else{
				// 	this.isShowAppointmentUI = true;
				// }
				// let self = this;
				axios.post('https://ssl.mihuahudong.com:21020/ecy_fz_record/back', {
						times: times
					})
					.then((res) => {
						console.log(res);

						if (res.status == 200) {
							if (res.data.code == 0) {
								this.preRegistrationNum = res.data.totalCount;
								console.log("this.preRegistrationNum==>" + this.preRegistrationNum);

							} else {
								console.log("totalCount请求失败 error code:" + res.data.code);
							}
						} else {
							console.log("人数状态有误 error status:" + res.status);
						}
					})
					.catch(function(err) {
						console.log(err);
					});
			},
			ellipsis: function(value){
				if (!value) return '';
				  if (value.length > 20) {
					return value.slice(0,20) + '...'
				  }
				  return value
			},
			newsPageClick: function(pageIndex) {
				this.newsPage = pageIndex;
			},
			newsClick: function(newInfo) {
				console.log("newsClicked==>");
				this.newInfo = newInfo;
				this.isNewPage = true;
				// $("html, body").animate({
				// 	scrollTop: 0
				// }, {
				// 	duration: 0,
				// 	easing: "swing"
				// });
			}
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.handleResize);
			//window.removeEventListener('scroll', this.handleScroll); 
		},
		mounted() {
			window.addEventListener('resize', this.handleResize);
			//window.addEventListener('scroll', this.handleScroll); 
			
			var pages = Math.ceil(this.newsList.length / this.newsViewNum);
			
			for (var i = 0; i < pages; i++) {
				this.newsPages.push(i);
			}
			
			var _this = this;
			// 初始化角色轮播
			var roleSwiper = new Swiper('#roleList', {
				autoplay: 2000,
				speed: 1500,
				loop: true,
				pagination: '#avatarBox',
				prevButton: '#rolePrevBtn',
				nextButton: '#roleNextBtn',
				paginationClickable: true,
				paginationBulletRender: function(swiper, index, className) {
					return '<span class="' + className + ' item"><span class="border"></span><img src=' + _this
						.roleList[index].hicon + ' /></span>';
				}
			});
			
			// 初始化游戏系统轮播
			var systemSwiper = new Swiper('#features', {
				autoplay: 2000,
				ection: 'horizontal',
				loop: true,
				pagination: '#selectBox',
				prevButton: '#featurePrevBtn',
				nextButton: '#featureNextBtn',
				effect: 'coverflow',
				centeredSlides: true,
				slidesPerView: 'auto',
				coverflow: {
					rotate: 25,
					stretch: 0,
					depth: 110,
					modifier: 0,
					slideShadows: false
				},
				paginationBulletRender: function(swiper, index, className) {
					return '<span class="' + className + ' item"></span>';
				}
			});

			// 初始化新闻系统轮播
			var nesSwiper = new Swiper('#pcnewsTop', {
				autoplay: 2000,
				ection: 'horizontal',
				loop: true,
				effect: 'coverflow',
				centeredSlides: true,
				slidesPerView: 'auto',
				coverflow: {
					rotate: 25,
					stretch: 0,
					depth: 110,
					modifier: 2,
					slideShadows: false
				}
			});
		},
		created: function() {
			console.log("created");
			this.Cherry_Blossoms();
			this.getPreRegistration(0);
			// this.initFaceBook();
			// this.getFacebookPage();
		},
		data() {
			return {
				isShowAppointmentUI: false,
				i: 0,
				videoUrl: '',
				newsPage: 0,
				isShowVideo: false,
				newsViewNum: 5,
				preRegistrationNum: 0,
				selectIndex: 1,
				isMoving: false,
				isNewPage: false,
				roleList: [{
						name: "雲上武神",
						cv: "川澄綾子",
						des: "青羽的第三形態，終於到達了武的巔峰，成為真正的武神形態，此時她的氣質卻隱去了外在的鋒芒，轉為內在的強大和平靜",
						img: require("../../assets/pc/role1.png"),
						hicon: require("../../assets/pc/hicon1.png"),
						rinfo: require("../../assets/pc/rinfo1.png")
					},
					{
						name: "十方皆斬",
						cv: "釘宮理惠",
						des: "雷琪爾的第二形態，使用名為“斬滅”的三把太刀進行戰鬥，對弱者毫不留情，對毀滅敵人一事十分熱衷",
						img: require("../../assets/pc/role2.png"),
						hicon: require("../../assets/pc/hicon2.png"),
						rinfo: require("../../assets/pc/rinfo2.png")
					},
					{
						name: "詭謀冥燈",
						cv: "悠木碧",
						des: "跟隨克莉絲四處征戰的天樞參謀，具有僅次於妮莎的智謀，真是目的和身份成謎，本是關心他人的溫柔戰姬，卻不知為何幾乎在一夜之間轉為了惡劣殘忍的性格",
						img: require("../../assets/pc/role3.png"),
						hicon: require("../../assets/pc/hicon3.png"),
						rinfo: require("../../assets/pc/rinfo3.png")
					},
					{
						name: "詭謀冥燈2",
						cv: "悠木碧",
						des: "跟隨克莉絲四處征戰的天樞參謀，具有僅次於妮莎的智謀，真是目的和身份成謎，本是關心他人的溫柔戰姬，卻不知為何幾乎在一夜之間轉為了惡劣殘忍的性格",
						img: require("../../assets/pc/role4.png"),
						hicon: require("../../assets/pc/hicon4.png"),
						rinfo: require("../../assets/pc/rinfo4.png")
					},
					{
						name: "詭謀冥燈3",
						cv: "悠木碧",
						des: "跟隨克莉絲四處征戰的天樞參謀，具有僅次於妮莎的智謀，真是目的和身份成謎，本是關心他人的溫柔戰姬，卻不知為何幾乎在一夜之間轉為了惡劣殘忍的性格",
						img: require("../../assets/pc/role5.png"),
						hicon: require("../../assets/pc/hicon5.png"),
						rinfo: require("../../assets/pc/rinfo5.png")
					},
					{
						name: "詭謀冥燈4",
						cv: "悠木碧",
						des: "跟隨克莉絲四處征戰的天樞參謀，具有僅次於妮莎的智謀，真是目的和身份成謎，本是關心他人的溫柔戰姬，卻不知為何幾乎在一夜之間轉為了惡劣殘忍的性格",
						img: require("../../assets/pc/role6.png"),
						hicon: require("../../assets/pc/hicon6.png"),
						rinfo: require("../../assets/pc/rinfo6.png")
					}
					
				],
				rewardThresholdList: [{
						img_name: "reward1.png",
						num: 100000
					},
					{
						img_name: "reward2.png",
						num: 150000
					},
					{
						img_name: "reward3.png",
						num: 200000
					},
					{
						img_name: "reward4.png",
						num: 250000
					},
					{
						img_name: "reward5.png",
						num: 300000
					}
				],
				storyBGList:[
					{
						img: require("../../assets/pc/story_pic_1.png")
					},
					{
						img: require("../../assets/pc/story_pic_2.png")
					},
					{
						img: require("../../assets/pc/story_pic_3.png")
					}
				],
				storyBGListDown:[
					{
						img: require("../../assets/pc/story_pic_4.png")
					},
					{
						img: require("../../assets/pc/story_pic_5.png")
					}
					
				],
				options: {

					//           //为每个section设置背景色
					//           sectionsColor: ["#f00","#0f0","#00f"],
					// //用来控制slide幻灯片的箭头，设置为false，两侧的箭头会消失
					//           controlArrows: false,
					// //每一页幻灯片的内容是否垂直居中
					//           verticalCentered: false,
					// //字体是否随着窗口缩放而缩放
					//           resize: true,
					// //页面滚动速度
					//           scrollingSpeed: 700,
					// //定义锚链接，用户可以快速打开定位到某一页面；不需要加"#"，不要和页面中任意的id和name相同
					//           anchors: ["page1","page2","page3"],
					// //是否锁定锚链接
					//           lockAnchors: true,
					// //定义section页面的滚动方式，需要引入jquery.easings插件
					//           easing:,
					// //是否使用css3 transform来实现滚动效果
					//           css3: false,
					// //滚动到最顶部后是否连续滚动到底部
					//           loopTop: true,
					// //滚动到最底部后是否连续滚动到顶部
					//           loopBottom: true,
					// //横向slide幻灯片是否循环滚动
					//           loopHorizontal: false,
					// //是否循环滚动，不会出现跳动，效果很平滑
					//           continuousVertical: true,
					// //是否使用插件滚动方式，设为false后，会出现浏览器自带的滚动条，将不会按页滚动
					//           autoScrolling: false,
					// //是否包含滚动条，设为true，则浏览器自带的滚动条会出现，页面还是按页滚动，但是浏览器滚动条默认行为也有效
					//           scrollBar: true,
					// //设置每个section顶部的padding，当我们要设置一个固定在顶部的菜单、导航、元素等时使用
					//           paddingTop: "100px",
					// //设置每个section底部的padding，当我们要设置一个固定在底部的菜单、导航、元素等时使用
					//           paddingBottom: "100px",
					// //固定的元素，为jquery选择器；可用于顶部导航等
					//           fixedElements: ".nav",
					// //是否可以使用键盘方向键导航
					//           keyboardScrolling: false,
					// //在移动设置中页面敏感性，最大为100，越大越难滑动
					//           touchSensitivity: 5,
					// //设为false，则通过锚链接定位到某个页面不再有动画效果
					//           animateAnchor: false,
					// //是否记录历史，可以通过浏览器的前进后退来导航
					//           recordHistory: true,
					// //绑定菜单，设定相关属性和anchors的值对应后，菜单可以控制幻灯片滚动
					//           menu: '.nav',
					// //是否显示导航，设为true会显示小圆点作为导航
					//           navigation: true,
					// //导航小圆点的位置，可以设置为left或者right
					//           navigationPosition: right,
					// //鼠标移动到小圆点上时显示出的提示信息
					//           navigationTooltips: ["第一页","第二页","第三页"],
					// //是否显示当前页面小圆点导航的提示信息，不需要鼠标移上
					//           showActiveTooltip: true,
					// //是否显示横向幻灯片的导航
					//           slidesNavigation: true,
					// //横向幻灯片导航的位置，可以为top或者bottom
					//           slidesNavPosition: bottom,
					// //内容超过满屏时是否显示滚动条，需要jquery.slimscroll插件
					//           scrollOverflow: true,
					// //section选择器
					//           sectionSelector: ".section",
					// //slide选择器
					//           slideSelector: ".slide"

					licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
					// licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
					afterLoad: this.afterLoad,
					scrollOverflow: false,
					navigation: true, //是否显示导航，默认为false
					navigationPosition: 'right', //导航小圆点的位置
					scrollBar: false,
					keyboardScrolling: false, //是否可以使用键盘方向键导航，默认为true
					continuousVertical: false, /// 是否循环滚动，默认为false。如果设置为true，则页面会循环滚动，而不像loopTop或loopBottom那样出现跳动，注意这个属性和loopTop、loopBottom不兼容和，不要同时设置
					menu: '#menu',
					navigation: true,
					// anchors: ['page1', 'page2', 'page3'],
					//sectionsColor: ['#41b883', '#ff5f45', '#0798ec', '#fec401', '#1bcee6', '#ee1a59', '#2c3e4f', '#ba5be9', '#b4b8ab']
				},
				menuList: [{
						name_cn: "首頁.png",
						name_en: "TOP",
						jumpid: "#pc_top-root",
						id: 1,
						menuSrc: require('../../assets/pc/首頁.png')
					}/*删除预约奖励相关,
					{
						name_cn: "獎勵.png",
						name_en: "REWARD",
						jumpid: "#pc_reward-root",
						id: 2,
						menuSrc: require('../../assets/pc/獎勵.png')
					}*/,
					{
						name_cn: "新聞.png",
						name_en: "NEWS",
						jumpid: "#pc_news-root",
						id: 2,
						menuSrc: require('../../assets/pc/新聞.png')
					},
					{
						name_cn: "遊戲特色.png",
						name_en: "FEATURE",
						jumpid: "#pc_feature-root",
						id: 3,
						menuSrc: require('../../assets/pc/遊戲特色.png')
					},
					{
						name_cn: "人物介紹.png",
						name_en: "CHARACTER",
						jumpid: "#pc_role-root",
						id: 4,
						menuSrc: require('../../assets/pc/人物介紹.png')
					},
					{
						name_cn: "世界觀.png",
						name_en: "STORY",
						jumpid: "#pc_story-root",
						id: 5,
						menuSrc: require('../../assets/pc/世界觀.png')
					}
					/*,
										{name_cn:"充值中心",name_en:"STORE"}*/
				],
				newsList: [{
					date: '12/17/2021',
					title: '《阿卡夏計劃》简介',
					id: "2020110201"
				}],
				newsPages: [],
				newsTopList: [
					{
					img: require('../../assets/pc/newsTop1.jpg')
					},
					{
					img: require('../../assets/pc/newsTop2.jpg')
					},
					{
					img: require('../../assets/pc/newsTop3.jpg')
					},
					{
					img: require('../../assets/pc/newsTop4.jpg')
					},
					{
					img: require('../../assets/pc/newsTop5.jpg')
					}
				],
				featureList: [
					{
					img: require('../../assets/pc/feature1.jpg')
					},
					{
					img: require('../../assets/pc/feature2.jpg')
					},
					{
					img: require('../../assets/pc/feature3.jpg')
					},
					{
					img: require('../../assets/pc/feature4.jpg')
					},
					{
					img: require('../../assets/pc/feature5.jpg')
					},
					{
					img: require('../../assets/pc/feature6.jpg')
					}
				]

			}
		}
	}
</script>

 

<style>
	
	.cherry {
		overflow-x: hidden;
		position: absolute;
		/* z-index: 999; */
		width: 100%;
		height: 100%; 		/* 高度自己调整下 */
		top: 0;
		right: 0;
		/* border: 1px solid; */
	}
	.cherry img {
		width: 50px;
		height: 50px;
		margin-top: -5%;
		opacity: 0.5;
		position: absolute;
		z-index: 99;
	
		/* -webkit-transform:rotate3d(.6,1,.6,45deg);
	    -moz-transform:rotate3d(.6,1,.6,45deg);
	    -ms-transform:rotate3d(.6,1,.6,45deg);
	    -o-transform:rotate3d(.6,1,.6,45deg);
	    transform:rotate3d(.6,1,.6,45deg); */
	}
	
	.section {
		position: relative;
		text-align: center;
	}

	.main-content {
		width: 100%;
		height: 100%;
	}
	
	.newpage_footer-root {
		width: 100%;
		height: 388px;
		margin: 0 auto;
		background-image: url(../../assets/pc/copyright-bg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		margin-top: 0px;
		color: #fffcf9;
	}
	
	.newpage_footer-root .footer-info,
	#pc_footer-root .footer-info {
		width: 1240px;
		height: 384px;
		margin: 0 auto;
		/* background-image: url(../../assets/pc/footer-info.png);
		background-size: 100% 100%;
		background-position-x: -339px; */
		display: inline-block;
	}
	
	.newpage_footer-root .yszc,
	#pc_footer-root .yszc {
		display: block;
		cursor: pointer;
		height: 40px;
		margin-top: -74px;
		margin-left: 1040px;
		font-size: 20px;
	}
	

	.top-page {
		/* width: 100%;
		height: 100%;
		background: url(../../assets/pc/main_bg_01.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-position: center; */
		
		/* position: absolute; */
		  left: 0;
		  top: 0;
		  width: 100%;
		  height: 100%;
		  position: relative;
		  overflow: hidden;
		  background-color: #000000;
	}
	
	.top-page .bgv {
			margin-left: 50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
		}

	.top-page .pc_logo {
		position: absolute;
		left: 50%;
		bottom: 6.2rem;
		width: 5.21rem;
		height: 2.43rem;
		background: url(../../assets/pc/logo.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	.top-page .pc_play {
		position: absolute;
		left: 50%;
		bottom: 6.8rem;
		width: 1.68rem;
		height: 1.68rem;
		background: url(../../assets/pc/play-btn.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		cursor: pointer;
	}
	
	.pc_appointment_ui{
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.85);
		z-index: 999;
		
	}
	
	.top-page .pc_appointment {
		position: absolute;
		left: 50%;
		width: 3.8rem;
		height: 1.8rem;
		bottom: 5rem;
		background: url(../../assets/pc/downtips.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	.top-page .pc_download {
		position: absolute;
		left: 50%;
		width: 10rem;
		height: 1rem;
		bottom: 4.5rem;
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		/* border: 1px solid; */
	}
	.top-page .pc_line2{
		z-index: 1;
		bottom: 3.5rem;
	}
	.top-page .pc_line3{
		z-index: 2;
		bottom: 2.5rem;
	}

	.top-page .pc_download .item {
		display: inline-block;
		margin: auto 0.3rem;
		width: 2rem;
		height: 0.68rem;
		/* border: 1px solid orangered; */
	}

	.top-page .pc_download .google {
		background-image: url(../../assets/pc/gdown-btn.png);
		background-size: 100% 100%;
		cursor: pointer;
	}

	.top-page .pc_download .app {
		background-image: url(../../assets/pc/adown-btn.png);
		background-size: 100% 100%;
		cursor: pointer;
	}
	
	.top-page .pc_download .leidian {
		background-image: url(../../assets/pc/lddown-btn.png);
		background-size: 100% 100%;
	}
	.top-page .pc_download .bluestacks {
		background-image: url(../../assets/pc/bsdown-btn.png);
		background-size: 100% 100%;
	}
	
	.top-page .pc_download .kunyue {
		background-image: url(../../assets/pc/kunyuedown-btn.png);
		background-size: 100% 100%;
	}

	.top-page .pc_facebook_fans {
		position: absolute;
		left: 50%;
		width: 500px;
		height: 4.4rem;
		bottom: 0.6rem;
		/* background: url(../../assets/pc/facebook_fans_landscape.png);
		background-size: contain;
		background-repeat: no-repeat; */
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		cursor: pointer;
	}
	
	.top-page .pc_facebook_fans .pc-fb-container {
		position: absolute;
		      right: 0;
		      top: 0.45rem;
		      width: 100%;
			  height: 3.2rem;
			display: inline-block; 
	}
	
	
	 .__vuescroll .__refresh,
	    .__vuescroll .__load {
	      position: absolute;
	      width: 100%;
	      color: black;
	      height: 50px;
	      line-height: 50px;
	      text-align: center;
	      font-size: 16px;
	    }
	    .__vuescroll .__refresh svg,
	    .__vuescroll .__load svg {
	      margin-right: 10px;
	      width: 25px;
	      height: 25px;
	      vertical-align: sub;
	    }
	    .__vuescroll .__refresh svg.active,
	    .__vuescroll .__load svg.active {
	      transition: all 0.2s;
	    }
	    .__vuescroll .__refresh svg.active.deactive,
	    .__vuescroll .__load svg.active.deactive {
	      transform: rotateZ(180deg);
	    }
	    .__vuescroll .__refresh svg path,
	    .__vuescroll .__refresh svg rect,
	    .__vuescroll .__load svg path,
	    .__vuescroll .__load svg rect {
	      fill: #20a0ff;
	    }
	    .__vuescroll .__refresh svg.start,
	    .__vuescroll .__load svg.start {
	      stroke: #343640;
	      stroke-width: 4;
	      stroke-linecap: round;
	      -webkit-animation: loading-rotate 2s linear infinite;
	      animation: loading-rotate 2s linear infinite;
	    }
	    .__vuescroll .__refresh svg.start .bg-path,
	    .__vuescroll .__load svg.start .bg-path {
	      stroke: #f2f2f2;
	      fill: none;
	    }
	    .__vuescroll .__refresh svg.start .active-path,
	    .__vuescroll .__load svg.start .active-path {
	      stroke: #20a0ff;
	      fill: none;
	      stroke-dasharray: 90, 150;
	      stroke-dashoffset: 0;
	      -webkit-animation: loading-wipe 1.5s ease-in-out infinite;
	      animation: loading-wipe 1.5s ease-in-out infinite;
	    }
	

	.top-page .top-down {
		position: absolute;
		left: 50%;
		bottom: 0.01rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 0.8rem;
		height: 1rem;
		background: url(../../assets/pc/down-btn.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-animation: slideDown 1.2s linear infinite;
		animation: slideDown 1.2s linear infinite;
	}

	/* @-webkit-keyframes slideDown {
		0% {
			-webkit-transform: translateX(-50%);
					transform: translateX(-50%);
		}
		100% {
			-webkit-transform: translate(-50%, 40%);
					transform: translate(-50%, 40%);
		}
	} */
	@keyframes slideDown {
		0% {
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
		}

		100% {
			-webkit-transform: translate(-50%, 40%);
			transform: translate(-50%, 40%);
		}
	}

	.pc-news-page {
		width: 100%;
		height: 100%;
		background: url(../../assets/pc/main_bg_03.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-position: center;
	}

	.pc-news-page .pc_t-news {
		position: relative;
		left: 33.8%;
		top: 8%;
		width: 6rem;
		height: 0.8rem;
		background: url(../../assets/pc/title-news.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	
	.pc-news-page .pc-news-content-portrait{
		position: absolute;
		left: 50%;
		top: 50%;
		width: 14.18rem;
		height: 7.64rem;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		
	}
	.pc-news-page .pc-news-content-portrait .pc-news-right{
	position: absolute;
	      right: 0;
	      top: 0;
	      width: 5.47rem;
	      height: 7.64rem;
		  
	}
	.pc-news-page .pc-news-content-portrait .pc-news-left{
		position: absolute;
	      left: 0;
	      top: 0;
	      width: 8.47rem;
	      height: 7.64rem;
	}
	.pc-news-page .pc-news-content-portrait .pc-news-left .pc-news-left-top{
		/* position: absolute; */
		left: 0;
		top: 0;
		width: 8.47rem;
		height: 2.9rem;
	}
	
	
	.pc-news-page .pc-news-content-portrait .pc-news-left .pc-news-left-down{
		height: 3.78rem;
		width: 8.47rem;
	}
	
	.pc-news-page .pc-news-content-portrait .pc-news-left .pc-news-left-down .pc-news-container{
		position: relative;
		left: 0;
		top: 0;
		width: 8.47rem;
		height: 5.9rem;
		background: url(../../assets/pc/news-container-bg.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	
	.pc-news-page .pc-news-content {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 14.18rem;
		height: 7.64rem;
		/* width: 18.18rem;
		    height: 12.64rem; */
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		/* border: 1px solid green; */
	}

	.pc-news-page .pc-news-content .pc-news-top {
		position: absolute;
		left: 0;
		top: 0;
		width: 14.18rem;
		height: 3.69rem;
	}

	#pcnewsTop {
		/* height: 3.69rem; */
	}

	#pcnewsTop .pc-news-top-stage {
		margin: 1rem auto;
		width: 9.8rem;
		height: 1.8rem;
		pointer-events: none;
		/* border: 1px solid red; */
	}
	
	.pc-news-down {
		position: absolute;
		left: 0;
		top: 3rem;
		width: 14.18rem;
		height: 6rem;
	}
	
	.pc-news-down .item{
		display: inline-block;
		margin: auto 0.2rem;
		/* width: 6.6rem;
		height: 6rem; */
	}

	.pc-news-down .item.news-container {
		position: relative;
		left: 0;
		top: 0;
		width: 6.6rem;
		height: 5.9rem;
		background: url(../../assets/pc/news-container-bg.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		
		/* display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap; */
	}
	
	.pc_news_list {
		
		position: absolute;
		left: -0.2rem;
		top: 0.8rem;
		width: 6rem;
		height: 4.6rem;
		/* width: 100%;
		height: 100vh; */
		/* width: 1134px;
		height: 544px; */
		/* padding-top: 60px;
		padding-left: 52px; */
	}
	
	.pc_news_list .news_item {
		display: inline-block;
		/* margin: auto 0rem; */
		height: 0.6rem;
		/* border-radius: 10px; */
		/* box-sizing: border-box; */
		/* background-color: #ddd3e7; */
		text-align: left;
		text-decoration:underline;
		cursor: pointer;
		/* list-style-type: none; */
		font-family: "SourceHanSerifCN-Bold";
	}
	
	.pc_news_list .news_item p {
		/* padding-left: 14px; */
		
	}
	
	.pc_news_list .news_item .n_date {
		color: #4f4460;
		font-size: 0.18rem;
		/* padding-top: 14px; */
		/* border: 1px solid red; */
	}
	
	.pc_news_list .news_item .n_title {
		color: #4e435f;
		font-size: 0.18rem;
		margin-top: -8px;
		border: 1px solid skyblue;
	}
	
	.pc_news_page {
		/* position: absolute;
		left: -0.2rem;
		top: 5rem;
		width: 6rem;
		height: 0.6rem; */
		
		position: absolute;
		/* left: -0.2rem; */
		top: 5rem;
		width: 6rem;
		height: 0.6rem;
		/* border: 1px solid red; */
	}
	
	.pc_news_page .item {
		display: inline-block;
		width: 0.01rem;
		height: 1rem;
		cursor: pointer;
		margin: auto 0.1rem;
		font-size: 0.18rem;
		color: #55aaff;
	}
	
	.pc_news_page .active_item {
		color: #55ffff;
	}
	
	.pc-news-down .item.pc-news-facebook-container{
		position: relative;
		left: 0;
		top: 0;
		/* width: 6.6rem; */
		/* width: 6.6rem;
		height: 6rem; */
		/* background: url(../../assets/pc/news-fb-container-bg.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center; */
		border: 1px solid orangered;
	}
	
	.pc-feature-page {
		width: 100%;
		height: 100%;
		background: url(../../assets/pc/main_bg_04.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-position: center;
	}
	
	.pc-feature-page .pc_t-feature {
		position: relative;
		left: 33.8%;
		top: 8%;
		width: 6rem;
		height: 0.8rem;
		background: url(../../assets/pc/title-feature.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	
	.pc-feature-page .pc-feature-content{
		position: absolute;
		    left: 50%;
		    top: 50%;
		    width: 15.8rem;
		    height: 7.8rem;
		    /* background-size: contain;
		    background-repeat: no-repeat; */
		    -webkit-transform: translate(-50%, -50%);
		        -ms-transform: translate(-50%, -50%);
		            transform: translate(-50%, -50%);
		    /* margin-top: -0.5rem; */
	}
	
	.pc-feature-container-line {
		margin: 0.68rem auto;
		width: 11.2rem;
		height: 6.2rem;
		border: 1px solid lightblue;
	}
	
	/* .swiper-slide-duplicate-next {
		border: 2px solid skyblue;
	} */
	
	#features {
		width: 11.2rem;
		height: 6.2rem;
		margin-top: 0;
		/* border: 1px solid yellow; */
		border: none;
	}
	
	#features .stage {
		display: block;
		margin: 0.1rem 0.1rem;
		width: 11rem;
		height: 6rem;
		/* border: 1px solid green; */
	}
	
	.feture-btns {
		position: absolute;
		height: 1rem;
		width: 100%;
		top: 50%;
	}
	
	.feture-btns .btn {
		width: 0.6rem;
		height: 0.8rem;
		background-repeat: no-repeat;
		position: absolute;
		z-index: 99;
	}
	
	.feture-btns .btn.fbtn-left {
		background-image: url(../../assets/pc/l-btn.png);
		background-size: 100% 100%;
		left: 0rem;
	}
	
	.feture-btns .btn.fbtn-right {
		background-image: url(../../assets/pc/r-btn.png);
		background-size: 100% 100%;
		right: 0.5rem;
	}
	
	.select-box {
		
	}
	
	.select-box .item {
		width: 0.8rem;
		height: 0.08rem;
		border-radius: 0;
		cursor: pointer;
		background-color: orange;
		/* background: transparent; */
		/* opacity: 1; */
		margin: 0rem 0.15rem;
		/* position: relative; */
		/* border: 10px solid yellow; */
	}
	
	#selectBox {
		position: absolute;
		width: 15.8rem;
		height: 0.5rem;
		top: 7.5rem;
	}
	
	
	.pc-character-page {
		width: 100%;
		height: 100%;
		background: url(../../assets/pc/main_bg_05.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-position: center;
	}
	
	.pc-character-page .pc_t-character {
		position: relative;
		left: 33.8%;
		top: 8%;
		width: 6rem;
		height: 0.8rem;
		background: url(../../assets/pc/title-heros.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	
	.pc-character-content {
		    position: absolute;
		    left: 50%;
		    top: 40%;
		    width: 12.62rem;
		    height: 7rem;
		    -webkit-transform: translate(-50%, -50%) scale(0.9);
		        -ms-transform: translate(-50%, -50%) scale(0.9);
		            transform: translate(-50%, -50%) scale(0.9);
		    -webkit-transform-origin: 50% 50%;
		        -ms-transform-origin: 50% 50%;
		            transform-origin: 50% 50%;
		    /* margin-left: 0.7rem; */
	}
	
	#roleList {
		/* position: absolute; */
		 /* margin-top: 0.5rem; */
		/* top: 15%; */
	}
	
	.roles {
		
	}
	
	.roles .stage {
		display: block;
		margin: 0rem auto;
		width: 7.2rem;
		height: 11.2rem;
	}
	
	#role-stage-bg{
		position: absolute;
		left: 50%;
		top: 7.5rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 9rem;
		height: 2.5rem;
		background: url(../../assets/pc/stage-bg.png);
		background-size: contain;
		background-repeat: no-repeat;
	}
	
	#role-info{
		position: absolute;
		left: 65%;
		top: 3rem;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 3rem;
		height: 2.2rem;
		
	}
	
	.avatars {
		/* height: 0.9rem;
		margin: 0rem auto;
		text-align: center;
		width: 100%; */
		/* border: 1px solid red;
		width: 0rem;
		height: 1rem; */
		
	}
	
	.avatars .item {
		/* width: 1.33rem;
		height: 1.16rem;
		border-radius: 0;
		cursor: pointer;
		background: transparent;
		opacity: 1;
		margin: 0rem 0.15rem;
		position: relative; */
		
		width: 1.33rem;
		height: 1.16rem;
		border-radius: 0;
		cursor: pointer;
		background: transparent;
		opacity: 1;
		margin: 0.15rem 0rem;
		position: relative;
	}
	
	.avatars .item .border {
		
		top: -0.11rem;
		left: -0.12rem;
		display: inline-block;
		width: 1.56rem;
		height: 1.4rem;
		position: absolute;
		/* background-image: url(../../assets/mobile/hicon-kuang-bai.png); */
		background-size: 100% 100%;
	}
	
	.avatars .item img {
		width: 100%;
		height: 100%;
	}
	
	#avatarBox {
		/* position: absolute;
		width: 13rem;
		height: 1.5rem;
		top: 9.2rem; */
		
		position: absolute;
		width: 2rem;
		height: 9rem;
		top: 1rem;
		left: -1.8rem;
		/* border: 1px solid red; */
	}
	
	.avatars .item.swiper-pagination-bullet-active .border {
		background-image: url(../../assets/pc/hicon-kuang-zi.png);
		background-size: 100% 100%;
	}
	
	.avatars .item.swiper-pagination-bullet-active::before {
		content: '';
		width: 0.58rem;
		height: 0.18rem;
		position: absolute;
		/*background-image: url(../images/imgs.png);*/
		background-repeat: no-repeat;
		background-position: -3.92rem -2.29rem;
		background-size: 4.95rem;
		left: 0rem;
		bottom: 0.06rem;
		z-index: 1;
	}
	
	.avatars .item.swiper-pagination-bullet-active::after {
		content: '';
		width: 0.58rem;
		height: 0.18rem;
		position: absolute;
		/*background-image: url(../images/imgs.png);*/
		background-repeat: no-repeat;
		background-position: -3.32rem -2.29rem;
		background-size: 4.95rem;
		right: -0.04rem;
		top: 0.0rem;
		z-index: 2;
	}
	
	.btns {
		position: absolute;
		height: 1rem;
		width: 100%;
		top: 50%;
	}
	
	.btns .btn {
		width: 0.6rem;
		height: 0.8rem;
		background-repeat: no-repeat;
		/* background-size: 4.95rem; */
		position: absolute;
		/* top: 1.95rem; */
		z-index: 99;
	}
	
	.btns .btn.pc-btn-left {
		background-image: url(../../assets/pc/l-btn.png);
		background-size: 100% 100%;
		left: 1rem;
	}
	
	.btns .btn.pc-btn-right {
		background-image: url(../../assets/pc/r-btn.png);
		background-size: 100% 100%;
		right: 1rem;
	}
	
	.pc-story-page {
		width: 100%;
		height: 100%;
		background: url(../../assets/pc/main_bg_06.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-position: center;
	}
	
	.pc-story-page .pc_t-story {
		position: relative;
		left: 33.8%;
		top: 8%;
		width: 6rem;
		height: 0.8rem;
		background: url(../../assets/pc/title-story.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	
	.pc-story-page .pc-story-content {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 18.2rem;
		height: 7.5rem;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.pc-story-content .pc-story-container-bottom {
		position: absolute;
		    left: 50%;
		    bottom: 1.2rem;
		    width: 18.56rem;
		    height: 2.79rem;
		   -webkit-transform: translateX(-50%);
		        -ms-transform: translateX(-50%);
		            transform: translateX(-50%);
		    -webkit-transform-origin: 50% 50%;
		        -ms-transform-origin: 50% 50%;
		            transform-origin: 50% 50%;
		   display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: justify;
		        -ms-flex-pack: justify;
		            justify-content: space-between;
	}
	
	.pc-story-content .pc-story-container-bottom .story-content-item{
		      width: 6rem;
		      height: 4rem;
		      position: relative;
		      cursor: pointer;
			  margin-right: 2rem;
	}
	
	.pc-story-content .pc-story-container-bottom .story-content-item .story-content-img {
		height: 3.5rem;
		width: 6rem;
	}
	
	.pc-story-container-top {
			position: absolute;
		    left: 50%;
		    /* top: 0.8em; */
			bottom: 3.2rem;
		    width: 18.56rem;
		    height: 3.79rem;
		    -webkit-transform: translateX(-50%);
		        -ms-transform: translateX(-50%);
		            transform: translateX(-50%);
		    -webkit-transform-origin: 50% 50%;
		        -ms-transform-origin: 50% 50%;
		            transform-origin: 50% 50%;
		    display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-pack: justify;
		        -ms-flex-pack: justify;
		            justify-content: space-between;
	}
	
	.pc-footer {
		position: absolute;
		    left: 0;
		    bottom: 0;
		    width: 100%;
		    height: 1.5rem;
			background: url(../../assets/pc/copyright-bg.jpg);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		    /* background: #272727; */
	}
	
	.pc-footer .yszc {
		cursor: pointer;
		font-size: 0.28rem;
		color: #ffffff;
		margin-top: 1rem;
		margin-left: 13rem;
	}
	
	.pc-story-container-top .story-content-item-top {
		width: 8rem;
		height: 3rem;
		margin-right: 1rem;
	}
	
	.story-content-img-top{
		height: 3rem;
		width: 5rem;
	}

	.pc-reward-page {
		width: 100%;
		height: 100%;
		background: url(../../assets/pc/main_bg_02.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		background-position: center;
	}

	.pc-reward-page .pc_t-reward {
		position: relative;
		left: 33.8%;
		top: 8%;
		width: 6rem;
		height: 0.8rem;
		background: url(../../assets/pc/title-reward.png);
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	.pc-reward-page .pc-reward-content {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 18.18rem;
		height: 9.64rem;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		background: right / 60% url(../../assets/pc/reward_bg.png);
		background-repeat: no-repeat;
	}

	.pc-reward-page .pc-reward-content .reward-item {
		position: relative;
		left: 0;
		top: 20%;
		width: 18.18rem;
		height: 9.64rem;
		margin: 0rem 0.1rem;
		display: inline;
	}

	.pc-reward-page .pc-reward-content .reward-item .reward-completed-item {
		position: absolute;
		margin: 4.2rem -2.5rem;
		height: 1rem;
		width: 1.22rem;
	}

	.pc-reward-page .pc-reward-content .reward-item .reward-item-img {
		height: 6rem;
		width: 2.5rem;
	}

	.pc-right-menu {
		position: fixed;
		right: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.pc-right-menu .pc-close-box {
		position: absolute;
		right: 0;
		top: 50%;
		width: 0.5rem;
		height: 3.72rem;
		background: url(../../assets/pc/reward3.png) no-repeat;
		background-size: cover;
		cursor: pointer;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		-webkit-transition: 0.3s 0.1s;
		transition: 0.3s 0.1s;
	}

	.pc-right-menu .pc-close-box.hide {
		-webkit-transform: translate(100%, -50%);
		-ms-transform: translate(100%, -50%);
		transform: translate(100%, -50%);
		opacity: 0;
	}

	.pc-right-menu .pc-menu-box {
		position: absolute;
		right: 0;
		top: 50%;
		width: 1.97rem;
		height: 4.02rem;
		background: url(../../assets/pc/110.png) no-repeat;
		background-size: cover;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		-webkit-transition: 0.3s;
		transition: 0.3s;
	}

	.pc-right-menu .pc-menu-box .pc-menu-list {
		position: absolute;
		right: 0;
		top: 0.3rem;
		width: 1.96rem;
		height: 3.06rem;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.pc-right-menu .pc-menu-box .item_active {
		background: url(../../assets/pc/117.png);
		background-size: cover;
	}

	.pc-menu-list .item {
		/* width: 0.34rem;
		height: 0.18rem; */
		cursor: pointer;
		/* content: ""; */
		display: block;
		width: 1.96rem;
		height: 0.41rem;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 99;
	}

	.pc-right-menu .pc-menu-box .item_active::before {
		content: "";
		display: block;
		width: 1.96rem;
		height: 0.41rem;
		background: url(../../assets/pc/117.png) no-repeat;
		background-size: cover;
		z-index: 0;
	}

	#menu-item-img {
		top: 32%;
		width: 0.34rem;
		height: 0.18rem;
		cursor: pointer;
		z-index: 999;
	}
	
	.mask .pc_vclose {
		cursor: pointer;
		width: 60px;
		height: 60px;
		margin: 0px 0px;
		margin-left: 725px;
		background-image: url(../../assets/mobile/meuns-close-btn.png);
		background-size: 100% 100%;
		top: 162px;
		position: absolute;
		border: none;
		background-color: #00000000;
	}
	
	#pc_videoModal {
		padding-top: 244px;
	}
	
	#pc_videoFrame {
		width: 750px;
		height: 420px;
		position: relative;
	}
	
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.85);
		z-index: 999;
		padding-top: 0.1rem;
	}
	
</style>
