<template>
	<div id="pc_newpage">
		<div class="npage-bg"></div>
		<div class="newinfo-root">
			<div id="pc_none"></div>
			<div class="pc_npdiv">
				<div class="pc_np-title">
					<h1>{{title}}</h1>
				</div>
				<p>{{date}}</p>
				<div class="infos" v-if="newid=='2020110201'">			
					
					<img src="../../assets/pc/news/20110201_01.jpg" />
					<pre> 新的異次元秘境，崩壞的現實世界，阿卡夏是什麽，黑化的神明與過去的糾葛……歷時3年研發，2022年1月香港昆越科技將发布3D美少女戰姬角色扮演卡牌手游——《阿卡夏計劃》。
預約注册即將正式啟動，參與預約即可獲得開服禮物，搶跑新手期。
頂級日配聲優演繹特色美少女，UE4引擎精心製作宣傳視頻，明亮爽眼的游戲畫面，作爲指揮官的你需要引領麾下戰姬，找回記憶，探尋天命之塔的頂峰。
					</pre>
					<p>參與事前登錄的玩家將在遊戲正式推出時最先收到提醒，下載遊戲后即可收到開服禮物，特別的獎勵和能力提升將帶來更加爽快的游戲體驗。禮物中更包含限定專屬頭像框，表現與衆不同的身份</p>
					<img src="../../assets/pc/news/20110201_02.jpg" />
					<p>背景設定：</p>
					<br/>
					<p>「阿卡夏是宇宙所有顯化現象的基礎。它變成了所有顯化的現象。據說它是如此微妙，以至於除非它在世上顯化出來，否則它就無法被察覺。」
—— Nikola Tesla（1907）， 「Man’s Greatest Achievement」.</p>
					<p>科技的無節製發展，看似繁榮的人類社會暗流湧動，被人性光輝所掩蓋的，是靈魂的墮落與腐朽。

隨著人類對於基礎科學的究極探索終於觸碰到了禁忌的領域，那只存在於神話當中的代表著另一個世界入口的「奧博之門」被再次開啟。

隨之而來的則是那古老聖書中記載的象征著「災厄catastrophe」與「懲罰punishment」的「以太能量」開始擴散……

而終極武器「戰姬」的降臨，更將人類對於「力量」的渴求推向巔峰……

面對「以太能量」和「戰姬」的誘惑，平日被秩序和道德壓製的醜陋欲望在這一刻就像被海浪掀起來的淤泥，堂而皇之出現在了所有人面前。

陰謀，謊言，惡意，詆毀共同構成了新世界的主旋律……

為了挽救隨時可能自我毀滅的人類，「諾亞小組」的指揮官在「戰姬」鶴橋輝夜的陪伴下，登上了一切奧秘的終極「天命之塔」。

然而讓他沒想到的是，自己救世的舉動，卻讓人類陷入了更加萬劫不復的深淵……

真正的災難，在這一刻降臨了……</p>
					<img src="../../assets/pc/news/20110201_03.jpg" />
					<p>·輕質畫風，初心快樂</p>
					<br/>
					<p>回歸二次元本真模樣，不常見的亮色簡潔扁平輕質感的立繪與UI，不僅是你的肝，你的頭發，我們還要呵護你的眼！我們只想讓你打開遊戲，感受輕松。體驗遊戲最初的快樂。</p>
					<img src="../../assets/pc/news/20110201_04.jpg" />
					<p>·頂級聲優，異世羅曼</p>
					<br/>
					<p>無論喜歡中文配音亦或是日文配音，都滿足你！聲情並茂體會奧博世界的羅曼。
井口裕香，上阪堇，植田佳奈，小倉唯，茅野愛衣，釘宮理惠，佐倉綾音，戶松遙，悠木碧 等（敬稱略・五十音順）</p>
					<p>·新歡舊愛，隨心換姬</p>
					<p>不用小心翼翼計算升級材料，喜歡就養！不用延遲滿足，當下就讓喜歡的戰姬成為最強，加入編隊！想換新的戰姬只要一鍵回退，後悔藥想吃就吃，隨時帶著新戰姬一起馳騁異世界。</p>
					<img src="../../assets/pc/news/20110201_05.jpg" />
					<p>·自主定制，任你指揮</p>
					<p>PVP與PVE系統多樣，無論是想要「社交」還是「單機」都能得到滿足。遊戲就是要隨你心意，不用被強製！走劇情，肝日常，養戰姬，爬塔刷刷刷，護送物資還有更多系統開發中……</p>
					<img src="../../assets/pc/news/20110201_06.jpg" />
					<p>·自律作戰，爽快翻倍</p>
					<p>3D即時戰鬥，4vs4~4vsN戰姬團戰一觸即發。每位戰姬至少擁有4個技能，智能演算最優施法，一鍵自律戰鬥，自動施法，開啟絢麗技能。</p>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default{
		name: 'newpage',
		props: {
		  newid: String,
		  title:String,
		  date:String
		}
	}
</script>

<style>
	#pc_newpage .npage-bg{
		width: 100%;
		height: 26.041666666vw;
		background-image: url(../../assets/pc/newpage-bg.jpg);
		background-size: 100% 100%;
		/* margin-top: 80px; */
	}
	#pc_newpage .newinfo-root{
		font-family: "SourceHanSerifCN-Bold";
		width: 100%;
		background-color: #dacee4;
	}
	.pc_npdiv{
		width: 1200px;
		padding-bottom: 200px;
		margin: 0 auto;
		text-align: left;
	}
	.pc_npdiv p{
		margin: 0;
		padding: 0;
		margin-top: 10px;
		color: #584375;
	}
	.pc_np-title{
		padding-top: 40px;
		border-bottom: 1px dashed   #7b4117;
		text-align: left;
	}
	.pc_np-title h1{
		line-height: 60px;
		font-weight: 600;
		font-size: 30px;
		color: #2c1b3a;
	}
	
	.pc_npdiv .infos{
		padding-top: 60px;
		text-align: left;
	}
	.pc_npdiv .infos img{
		padding-top: 10px;
	}
	.pc_npdiv .infos pre,
	.pc_npdiv .infos p{
		color: #584375;
		line-height: 30px;
		padding: 0;
		margin: 0;
	}
	
	@media all and (max-width:1300px){
		.pc_npdiv{
			width: 92.3706%;
			padding-bottom: 200px;
			margin: 0 auto;
			text-align: left;
		}
		.pc_npdiv .infos img{
			width: 100%;
		}
	}
</style>
